<template>
  <v-container>
    <div class="d-flex align-center mb-4">
      <h1 class="text-h5">
        <span class="work-sans">Feed de transações</span>
      </h1>

      <v-spacer />

      <v-chip class="mr-2" color="surface" link @click="handleOrder()">
        {{ pagination.order === 0 ? "Mais Recentes" : "Mais Antigo" }}

        <v-icon right>
          {{ pagination.order === 0 ? "mdi-chevron-down" : "mdi-chevron-up" }}
        </v-icon>
      </v-chip>
    </div>

    <loader-view v-if="loading" />

    <section v-else>
      <FilterGroup class="mb-4" :filters="methods" :queryKey="'type'" />

      <FilterInput
        class="mb-4"
        :dataFilters="require('@/assets/filters/feed.json')"
      />

      <section v-if="transactions.length > 0">
        <v-list class="transparent pa-0" nav>
          <div v-for="(item, i) in transactions" :key="i">
            <v-card class="shadow mb-2" color="background">
              <v-list-item>
                <v-row align="center">
                  <v-col>
                    <router-link :to="`/user/${item.customer.id}`">
                      <v-chip color="primary" link>
                        <v-icon left small>mdi-account</v-icon>
                        <span>{{ item.customer.name }}</span>
                      </v-chip>
                    </router-link>
                  </v-col>

                  <v-col>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ handleType(item.type).label }}
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        <span v-if="item.contractName">
                          {{ item.contractName }}
                        </span>

                        <span v-if="item.type === 'commission'">
                          {{ item.data.userContratoID }}
                        </span>

                        <span v-if="item.type === 'contract'">
                          {{ item.data.userContratoID }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-col>

                  <v-col>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ currencyMask(item.value) }}
                        <span class="text-caption">
                          {{ item.currency }}
                        </span>
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        Valor da transação
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-col>

                  <v-col>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.createdAt }}
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        Data de realização
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-card>
          </div>
        </v-list>

        <!-- pagination -->
        <v-pagination
          v-model="pagination.page"
          color="primary"
          :length="pagination.pageCount"
          :total-visible="10"
          @input="getData()"
        />
      </section>

      <div v-else class="text-center mb-4">
        <span class="subtext--text">Nenhum resultado encontrado</span>
      </div>
    </section>
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask, handlePagination } from "@/utils";
import FilterInput from "@/components/filters/FilterInput";
import FilterGroup from "@/components/filters/FilterGroup";
const moment = require("moment");

export default {
  data() {
    return {
      loading: true,
      methods: [
        { label: "Todos", value: "" },
        { label: "Depósitos", value: "deposits" },
        { label: "Saques", value: "withdrawals" },
        { label: "Comissões", value: "commissions" },
        { label: "Produtos", value: "products" },
      ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        order: 0,
      },
      transactions: [],
    };
  },

  components: {
    FilterInput,
    FilterGroup,
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    ["$route.query"](value) {
      this.handleQuery(value);
    },
  },

  methods: {
    async getData(filter = {}) {
      try {
        this.transactions = [];

        const payload = {
          method: "getallTransactions",
          page: this.pagination.page - 1,
          ...filter,
        };

        await request(payload).then(async (res) => {
          let counter = 0;

          let deposits = [];
          let withdrawals = [];
          let commissions = [];
          let products = [];

          if (
            this.$route.query.type === "deposits" ||
            !this.$route.query.type
          ) {
            await res.depositos.map((e) => {
              deposits.push({
                type: "deposit",
                id: e.depositoID,
                contractName: e.nomeContrato,
                createdAt: e.data,
                value: e.valor,
                currency: e.moeda,
                customer: {
                  id: e.userID,
                  name: e.name,
                },
                data: e,
              });
            });

            counter += res.totalDepositos;
          }

          if (
            this.$route.query.type === "withdrawals" ||
            !this.$route.query.type
          ) {
            await res.saques.map((e) => {
              withdrawals.push({
                type: "withdraw",
                id: e.saqueId,
                createdAt: e.data,
                contractName: e.nomeContrato,
                value: e.valor,
                currency: "BRL",
                customer: {
                  id: e.userID,
                  name: e.nome,
                },
                data: e,
              });
            });

            counter += res.totalSaques;
          }

          if (
            this.$route.query.type === "commissions" ||
            !this.$route.query.type
          ) {
            await res.userTransactionsComissoes.map((e) => {
              commissions.push({
                type: "commission",
                id: e.id,
                createdAt: e.data,
                value: e.valor,
                currency: "BRL",
                customer: {
                  id: e.userID,
                  name: e.name,
                },
                data: e,
              });
            });

            counter += res.totalUserTransactionsComissoes;
          }

          if (
            this.$route.query.type === "products" ||
            !this.$route.query.type
          ) {
            await res.usersContratotransactions.map((e) => {
              products.push({
                type: "contract",
                id: e.id,
                createdAt: e.data,
                value: e.valor,
                currency: "BRL",
                customer: {
                  id: "3",
                  name: e.name,
                },
                data: e,
              });
            });

            counter += res.totalUsersContratotransactions;
          }

          let transactions = [
            ...deposits,
            ...withdrawals,
            ...commissions,
            ...products,
          ];

          transactions.sort(function (a, b) {
            return moment(b.createdAt) - moment(a.createdAt);
          });

          this.transactions = transactions;

          this.pagination.pageCount = handlePagination(counter);
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleQuery(value) {
      this.loading = true;
      this.pagination.page = 1;

      const query = {
        nameFilter: value.name || undefined,
        emailFilter: value.email || undefined,
        contratoIDFiltro: value.contractId || undefined,
        dataInicial: value.period ? value.period.split(",")[0] : undefined,
        dataFinal: value.period ? value.period.split(",")[1] : undefined,
      };

      this.getData(query);
    },

    handleOrder() {
      this.pagination.order = this.pagination.order === 1 ? 0 : 1;
      this.getData();
    },

    handleType(value) {
      switch (value) {
        case "deposit":
          return {
            label: "Depósito",
            color: "",
          };
        case "withdraw":
          return {
            label: "Saque",
            color: "",
          };
        case "commission":
          return {
            label: "Comissão",
            color: "",
          };
        case "contract":
          return {
            label: "Contrato",
            color: "",
          };
      }
    },

    currencyMask,
  },
};
</script>

<style></style>
